import { useEffect, useRef, Fragment } from 'react';
import { Video, TruvaConfig } from '@root/types';
import { EnvProvider, EnvVariables } from '@shared/useEnv';
import { Provider } from 'react-redux';
import deeplyCamelize from '@shared/deeplyCamelize';
import configureLaunchDarkly from '@root/configureLaunchDarkly';
import configureStore from '@store/configureStore';
import configureTranslations from '@root/configureTranslations';
import VideoEmbed, { VideoEventAttributes } from './VideoEmbed';

type Props = {
  // eslint-disable-next-line camelcase
  data: { video: object; event_attributes: object };
  env: EnvVariables;
  launchDarklyAllFlagsState: { [flag: string]: boolean };
  truvaConfig: TruvaConfig;
};

const Wrapper = ({ data, env, launchDarklyAllFlagsState, truvaConfig }: Props) => {
  useEffect(() => {
    const translations = JSON.parse(truvaConfig.translationsJSON);
    configureTranslations(truvaConfig.locale, translations);
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const store = useRef(configureStore({}, {}));

  const LaunchDarkly = configureLaunchDarkly(
    Fragment,
    launchDarklyAllFlagsState,
    env.launchDarklyClientSideId,
    env.railsEnv,
  );

  const { video, eventAttributes }: { video: Video; eventAttributes: VideoEventAttributes } =
    deeplyCamelize(data);

  return (
    <EnvProvider value={env}>
      <Provider store={store.current}>
        <LaunchDarkly>
          <VideoEmbed video={video} eventAttributes={eventAttributes} />
        </LaunchDarkly>
      </Provider>
    </EnvProvider>
  );
};

export default Wrapper;
