import { SourceContent, SourcePage } from '@root/store/actions/videoAnalytics';
import useIFrameInteractionTracking from '@components/shared/useIFrameInteractionTracking';
import cn from 'classnames';
import s from './ThreeDTour.module.less';

export type Props = {
  tourEmbedUrl: string;
  sourceContent: SourceContent;
  sourcePage: SourcePage;
  fullscreen?: boolean;
};

const ThreeDTour = ({ tourEmbedUrl, sourceContent, sourcePage, fullscreen = false }: Props) => {
  useIFrameInteractionTracking({
    shouldTrack: tourEmbedUrl,
    sourceContent,
    sourcePage,
    iFrameId: 'ThreeDTour',
  });

  return (
    <iframe
      src={tourEmbedUrl}
      title="3D Tour"
      id="ThreeDTour"
      data-testid="ThreeDTour"
      className={cn(fullscreen ? s.fullscreenContainer : s.container)}
    ></iframe>
  );
};

export default ThreeDTour;
