/*
  This file is automatically compiled by Webpack, along with any other files
  present in this directory. You're encouraged to place your actual application
  logic in a relevant structure within app/javascript and only use these pack
  files to reference that code so it'll be compiled.

  To reference this file, add <%= javascript_pack_tag 'application' %> to the
  appropriate layout file, like app/views/layouts/application.html.erb

  Uncomment to copy all static images under ../images to the output folder and
  reference them with the image_pack_tag helper in views
  (e.g <%= image_pack_tag 'rails.png' %>) or the `imagePath` JavaScript
  helper below.

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

 */

// Support component names relative to this directory:
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import './polyfills';

const componentRequireContext = require.context(
  'components',
  true,
  /.*layouts\/(Truva|Admin|VideoEmbed|ThreeDTourEmbed)$/,
);

const ReactRailsUJS = require('react_ujs');

// eslint-disable-next-line react-hooks/rules-of-hooks
ReactRailsUJS.useContext(componentRequireContext);

/**
 * This is because we want to use async scripts in our HTML
 * https://github.com/reactjs/react-rails/issues/218
 * */
ReactRailsUJS.mountComponents();
