import { useEffect } from 'react';
import VideoPlayer from '@components/shared/VideoPlayer';
import { useDispatch } from 'react-redux';
import { Video, ListingStatus } from '@root/types';
import actions from '@store/actions';
import { receiveNoUser } from '@store/actions/currentUser/fetchCurrentUser';

export type VideoEventAttributes =
  | {
      truvaListingId: string;
      buildingName: string;
      listingStatus: ListingStatus;
      listingName: string;
      isExclusiveListing: boolean;
    }
  | {
      buildingId: string;
      buildingName: string;
      hasActiveListing: boolean;
    }
  | {
      landlordName: string;
    };

type Props = {
  video: Video;
  eventAttributes: VideoEventAttributes;
};

const VideoEmbed = ({ video, eventAttributes }: Props) => {
  const urlParams = new URLSearchParams(window.location.search);
  const sourcePage = urlParams.get('sourcePage') || document.referrer;
  const sourceContent = urlParams.get('sourceContent') || 'embedded video player';
  const fitWithinViewport = urlParams.get('fitWithinViewport') === 'true';
  const dispatch = useDispatch();
  let otherParams = {};

  if (urlParams.get('microsite')) {
    otherParams = {
      buildingId: urlParams.get('buildingId'),
      buildingName: urlParams.get('buildingName'),
      micrositeDomain: urlParams.get('micrositeDomain'),
      micrositeId: urlParams.get('micrositeId'),
      landlordName: urlParams.get('landlordName'),
      micrositePreview: urlParams.get('micrositePreview'),
    };
  }

  const dispatchSetPageThenImpression = async () => {
    await dispatch(actions.setVideoEmbedPage({ ...eventAttributes, ...otherParams }));
    await dispatch(receiveNoUser(undefined));
  };

  useEffect(() => {
    dispatchSetPageThenImpression();
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VideoPlayer
      video={video}
      sourcePage={sourcePage}
      sourceContent={sourceContent}
      fitWithinViewport={fitWithinViewport}
    />
  );
};

export default VideoEmbed;
