import { useEffect } from 'react';
import { ListingStatus } from '@root/types';
import { useDispatch } from 'react-redux';
import actions from '@store/actions';
import { receiveNoUser } from '@store/actions/currentUser/fetchCurrentUser';
import { threeDTourImpression } from '@store/actions/videoAnalytics';
import ThreeDTour from './ThreeDTour';

export type VideoEventAttributes =
  | {
      truvaListingId: string;
      buildingName: string;
      listingStatus: ListingStatus;
      listingName: string;
      isExclusiveListing: boolean;
    }
  | {
      buildingId: string;
      buildingName: string;
      hasActiveListing: boolean;
    }
  | {
      landlordName: string;
    };

type Props = {
  tourEmbedUrl: string;
  eventAttributes: VideoEventAttributes;
  fullscreen?: boolean;
};

const ThreeDTourEmbed = ({ tourEmbedUrl, eventAttributes, fullscreen = false }: Props) => {
  const urlParams = new URLSearchParams(window.location.search);
  const sourcePage = urlParams.get('sourcePage') || document.referrer;
  const sourceContent = urlParams.get('sourceContent') || 'embedded 3d tour';
  const dispatch = useDispatch();
  let otherParams = {};

  if (urlParams.get('microsite')) {
    otherParams = {
      buildingId: urlParams.get('buildingId'),
      buildingName: urlParams.get('buildingName'),
      micrositeDomain: urlParams.get('micrositeDomain'),
      micrositeId: urlParams.get('micrositeId'),
      landlordName: urlParams.get('landlordName'),
      micrositePreview: urlParams.get('micrositePreview'),
    };
  }

  const dispatchSetPageThenImpression = async () => {
    await dispatch(actions.setVideoEmbedPage({ ...eventAttributes, ...otherParams }));
    await dispatch(receiveNoUser(undefined));
    dispatch(
      threeDTourImpression({
        sourcePage,
        sourceContent,
      }),
    );
  };

  useEffect(() => {
    dispatchSetPageThenImpression();
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThreeDTour
      tourEmbedUrl={tourEmbedUrl}
      sourcePage={document.referrer}
      sourceContent="embedded 3d tour"
      fullscreen={fullscreen}
    />
  );
};

export default ThreeDTourEmbed;
