import { useEffect, useRef, Fragment } from 'react';
import { TruvaConfig } from '@root/types';
import { EnvProvider, EnvVariables } from '@shared/useEnv';
import { Provider } from 'react-redux';
import deeplyCamelize from '@shared/deeplyCamelize';
import configureLaunchDarkly from '@root/configureLaunchDarkly';
import configureStore from '@store/configureStore';
import configureTranslations from '@root/configureTranslations';
import ThreeDTourEmbed, { VideoEventAttributes } from './ThreeDTourEmbed';

type Props = {
  // eslint-disable-next-line camelcase
  data: { tour_embed_url: string; event_attributes: object };
  env: EnvVariables;
  launchDarklyAllFlagsState: { [flag: string]: boolean };
  truvaConfig: TruvaConfig;
};

const Wrapper = ({ data, env, launchDarklyAllFlagsState, truvaConfig }: Props) => {
  useEffect(() => {
    const translations = JSON.parse(truvaConfig.translationsJSON);
    configureTranslations(truvaConfig.locale, translations);
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const store = useRef(configureStore({}, {}));

  const LaunchDarkly = configureLaunchDarkly(
    Fragment,
    launchDarklyAllFlagsState,
    env.launchDarklyClientSideId,
    env.railsEnv,
  );

  const {
    tourEmbedUrl,
    eventAttributes,
    fullscreen = false,
  }: {
    tourEmbedUrl: string;
    eventAttributes: VideoEventAttributes;
    fullscreen?: boolean;
  } = deeplyCamelize(data);

  return (
    <EnvProvider value={env}>
      <Provider store={store.current}>
        <LaunchDarkly>
          <ThreeDTourEmbed
            tourEmbedUrl={tourEmbedUrl}
            eventAttributes={eventAttributes}
            fullscreen={fullscreen}
          />
        </LaunchDarkly>
      </Provider>
    </EnvProvider>
  );
};

export default Wrapper;
